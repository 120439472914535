import { Injectable } from "@angular/core";
import { webSocket, WebSocketSubject } from "rxjs/webSocket";
@Injectable()
export class DhanFeedService {
  private subject!: WebSocketSubject<any>;
  private clientId: string = '1000000836'; //1000000836386788-W-DHN1804
  private accessToken: string = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJkaGFuIiwicGFydG5lcklkIjoiIiwiZXhwIjoxNzE5NjU0NzI0LCJ0b2tlbkNvbnN1bWVyVHlwZSI6IlNFTEYiLCJ3ZWJob29rVXJsIjoiIiwiZGhhbkNsaWVudElkIjoiMTAwMDAwMDgzNiJ9.xQ_Qg2oK45XZE7CqiSL2WNDLzq6xc5YM0MhZ9rwcRB7Kc2c86gS690bRYvXq5knRtgh8XSgxFVvPpVMzHZaqnw';
  private isConnected = false;
  public connect(url: string) {
    this.subject = webSocket({
      url: url,
      binaryType: 'arraybuffer',
      serializer: (value) => value,
      deserializer: (value) => value,
      closeObserver: {
        next: () => this.closeObserverCallback()
      },
      openObserver: {
        next: () => {
          this.openObserverCallback();
        }
      }
    });
    this.subject.subscribe({
      next: (message: MessageEvent<any>) => this.onMessage(message.data),
      error: (err) => this.onError(err)
    });
  }


  public disconnect() {
    if (this.subject) {
      this.subject.complete();
    }
  }

  public send(message: ArrayBuffer | DataView) {
    if (!this.subject.closed && this.isConnected) {
      console.log(`sending message to Socket`);
      this.subject.next(message);
    }
  }

  private closeObserverCallback() {

  }

  private openObserverCallback() {
    this.isConnected = true;
    setTimeout(() => this.send(DhanRequestBuilder.getSocketAuthRequest(this.clientId, this.accessToken)), 10);
    setTimeout(() => this.send(DhanRequestBuilder.getQuoteRequest(this.clientId, ['8@880173', '2@41616', '1@20261', '4@511431', '4@544026'], true)), 2000);
  }

  private onMessage(message: ArrayBuffer) {
    const dataView = new DataView(message);
    const responseCode = dataView.getInt8(0);
    switch (responseCode) {
      case 50:
        this.processDisconnection(dataView);
        break;
      case 4:
        this.processQuote(dataView);
        break;
      case 5:
        this.processOIQuote(dataView);
        break;
    }
  }

  private onError(err: any) {
    this.isConnected = false;
    console.error(err);
  }

  private processDisconnection(message: DataView) {
    const errorCode = message.getInt16(8, true);
    switch (errorCode) {
      case 805:
        console.error('Disconnected: No. of active websocket connections exceeded');
        break;
      case 806:
        console.error('Disconnected: Subscribe to Data APIs to continue');
        break;
      case 807:
        console.error('Disconnected: Access Token is expired');
        break;
      case 808:
        console.error('Disconnected: Invalid Client ID');
        break;
      case 809:
        console.error('Disconnected: Authentication Failed - check');
        break;
    }
    this.isConnected = false;
  }

  private processQuote(message: DataView) {
    let data: any = {};
    data.scripCode = message.getInt32(5);
    data.segmentId = message.getInt8(4);
    data.LTP = message.getFloat32(9);
    data.ATP = message.getFloat32(19);
    data.LTQ = message.getInt16(13);
    data.volume = message.getInt32(23);
    console.log('processQuote', data);
  }

  private processOIQuote(message: DataView) {
    let data: any = {};
    data.scripCode = message.getInt32(5);
    data.segmentId = message.getInt8(4);
    data.OI = message.getInt32(9);
    console.log('processOIQuote', data);
  }
}

export class DhanRequestBuilder {
  private static readonly HEADER_PACKET_SIZE = 83;
  private static readonly AUTH_PACKET_SIZE = 502;
  private static readonly INSTRUMENT_LENGTH_SIZE = 4;
  private static readonly INSTRUMENT_SIZE = 21;

  private static pad(data: string, length: number, fillChar: string, paddingSide: 'LEFT' | 'RIGHT') {
    if ((length + 1) >= data.length) {
      switch (paddingSide) {
        case 'LEFT':
          data = Array(length + 1 - data.length).join(fillChar) + data;
          break;
        case 'RIGHT':
        default:
          data += Array(length + 1 - data.length).join(fillChar);
      }
    }
    return data;
  }

  private static getHeaderPacket(requestType: number, clientId: string, messageLength: number) {
    var headerPacket = new ArrayBuffer(DhanRequestBuilder.HEADER_PACKET_SIZE + messageLength);
    var dataView = new DataView(headerPacket, 0, headerPacket.byteLength);
    clientId = DhanRequestBuilder.pad(clientId, 30, '', 'RIGHT');
    const packetEndPart = Array(50).fill('').join('');
    dataView.setInt8(0, requestType);
    dataView.setUint16(1, (DhanRequestBuilder.HEADER_PACKET_SIZE + messageLength), true);
    for (var i = 0; i < 30; i++) {
      dataView.setInt8(3 + i, clientId.charCodeAt(i));
    } for (var i = 0; i < 50; i++) {
      dataView.setInt8(34 + i, packetEndPart.charCodeAt(i));
    }
    return dataView;
  }

  static getSocketAuthRequest(clientId: string, accessToken: string) {
    const dataView = DhanRequestBuilder.getHeaderPacket(11, clientId, DhanRequestBuilder.AUTH_PACKET_SIZE);
    accessToken = DhanRequestBuilder.pad(accessToken, 50, '', 'RIGHT');
    const packetEndPart = "2P";
    for (var i = 0; i < 500; i++) {
      dataView.setInt8(83 + i, accessToken.charCodeAt(i));
    }
    for (var i = 0; i < 2; i++) {
      dataView.setInt8(583 + i, packetEndPart.charCodeAt(i));
    }
    console.log(dataView);
    return dataView.buffer;
  }

  static getQuoteRequest(clientId: string, scrips: string[], isSubscribe: boolean) {
    const scripsPacketLength = (scrips.length * DhanRequestBuilder.INSTRUMENT_SIZE);
    const dataView = DhanRequestBuilder.getHeaderPacket(isSubscribe ? 17 : 18, clientId, DhanRequestBuilder.INSTRUMENT_LENGTH_SIZE + scripsPacketLength);
    dataView.setInt32(DhanRequestBuilder.HEADER_PACKET_SIZE, scrips.length);
    scrips.forEach((scrip, index) => {
      const token = (scrip.split('@')[1])
      let scripPadded = DhanRequestBuilder.pad(token, 20, '', 'RIGHT');
      let startIndex = DhanRequestBuilder.HEADER_PACKET_SIZE + DhanRequestBuilder.INSTRUMENT_LENGTH_SIZE + (index * DhanRequestBuilder.INSTRUMENT_SIZE);
      dataView.setInt8(startIndex, scrip.charCodeAt(0));
      for (var i = 0; i < 20; i++) {
        dataView.setInt8((startIndex + 1) + i, scripPadded.charCodeAt(i));
      }
    });
    const endIndex = DhanRequestBuilder.HEADER_PACKET_SIZE + DhanRequestBuilder.INSTRUMENT_LENGTH_SIZE + scripsPacketLength;
    // const packetEndPart = new Array(2100 - (scrips.length * 21)).fill('').join('');
    // for (var i = 0; i < packetEndPart.length; i++) {
    //   dataView.setInt8(endIndex + i, packetEndPart.charCodeAt(i));
    // }
    console.log(dataView.buffer);
    return dataView.buffer;
  }
}
