import { Component } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrl: './news.component.scss'
})
export class NewsComponent {
  articles: any[];
  payload: any = {
    sort: [['publishedDate', 'DESC']],
    startRow: 0,
    endRow: 100
  };
  constructor(private http: HttpClient) {
    this.articles = [];
  }

  private getNews() {
    const url = `http://165.22.222.184:3020/articles`;
    this.http.post(url, this.payload).subscribe((response: any) => {
      this.articles.push(...(response?.articles || []));
    })
  }

  loadNext() {
    this.payload.startRow = this.articles.length;
    this.payload.endRow = this.articles.length + 100;
    this.getNews();
  }

  downloadAttachment(url: string) {
    if (url) {
      window.open(url);
    }
  }

  refresh() {
    this.articles = [];
    this.payload.startRow = 0;
    this.payload.endRow = 100;
    this.getNews();
  }
}
